/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https:   //www.gatsbyjs.com/docs/use-static-query/
 */

import "./layout.scss";

import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ImLocation } from "@react-icons/all-files/im/ImLocation";
import { ImPhone } from "@react-icons/all-files/im/ImPhone";
import { ImPrinter } from "@react-icons/all-files/im/ImPrinter";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import Header from "./header";

const footer = css`
  background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 86.75%,
      rgba(194, 145, 46, 1) 87%,
      rgba(194, 145, 46, 1) 87.25%,
      rgba(0, 0, 0, 0) 87.5%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      rgba(194, 145, 46, 1) 35%,
      rgba(194, 145, 46, 1) 55%,
      rgba(0, 0, 0, 0) 55%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      rgba(194, 145, 46, 1) 35%,
      rgba(194, 145, 46, 1) 65%,
      rgba(0, 0, 0, 0) 65%,
      rgba(0, 0, 0, 0) 100%
    );
  background-position: 96px center, -75px calc(100% + 75px),
    calc(100vw - 125px) calc(100% + 125px);
  background-repeat: no-repeat;
  background-size: 100% 100%, 150px 150px, 250px 250px;
`;

const FabContainer = styled(Row)`
  z-index: 1;
  position: sticky;
  bottom: -8px;
  flex-direction: row;
  justify-content: center;
  & > div {
    justify-content: center;
  }
  &.is-pinned {
    flex-direction: column;
    padding-bottom: 12px;
    & > div {
      width: 100%;
      justify-content: flex-end;
    }
  }
`;
const Daftar = styled.a``;
const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query Site {
        site {
          siteMetadata {
            title
          }
        }
        files: allFile(
          filter: { name: { in: ["twitter", "facebook", "instagram"] } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(toFormat: WEBP) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        logo: file(name: { eq: "logo-2023-full" }) {
          childImageSharp {
            fixed(toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        daftar: file(name: { eq: "daftar" }) {
          childImageSharp {
            fixed(width: 200, toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        wa: file(name: { eq: "chatwa" }) {
          childImageSharp {
            fixed(width: 200, toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        wa2: file(name: { eq: "chatwaigd" }) {
          childImageSharp {
            fixed(width: 200, toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  );
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
      { threshold: [1] }
    );
    observer.observe(document.getElementById("fabs"));
  }, []);

  return (
    <>
      <Header
        siteTitle={
          data.site.siteMetadata?.title || `Service excellence with smile`
        }
      />
      <main>
        <Container>
          {children}
          <FabContainer
            id='fabs'
            className='mt-3 mb-2 d-flex align-items-center align-content-center'>
            <Col
              xs={"12"}
              md={"auto"}
              className='d-flex align-items-center align-content-center'>
              <Daftar
                href='https://wa.me/6282140009403'
                className='d-flex align-items-center align-content-center'>
                <Img fixed={data.wa.childImageSharp.fixed} />
              </Daftar>
            </Col>
            <Col
              xs={"12"}
              md={"auto"}
              className='d-flex align-items-center align-content-center'>
              <Daftar
                href='https://rsgmiikbw.com/daftar'
                className='d-flex align-items-center align-content-center'>
                <Img fixed={data.daftar.childImageSharp.fixed} />
              </Daftar>
            </Col>
            <Col
              xs={"12"}
              md={"auto"}
              className='d-flex align-items-center align-content-center'>
              <Daftar
                href='https://wa.me/6285175432399'
                className='d-flex align-items-center align-content-center'>
                <Img fixed={data.wa2.childImageSharp.fixed} />
              </Daftar>
            </Col>
          </FabContainer>
        </Container>
      </main>
      <footer className='bg-dark text-white p-4' css={footer}>
        <Container>
          <Row>
            <Col sm={"4"}>
              <Img fixed={data.logo.childImageSharp.fixed} />
            </Col>
            <Col sm={"4"}>
              <h4>Rumah Sakit Gigi dan Mulut</h4>
              <h4>IIK Bhakti Wiyata</h4>
              <Row className='mb-3 mt-4'>
                <Col>
                  <ImLocation className='mr-2' />
                  Jl. KH. Wahid Hasyim 65, Kediri
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <ImPhone className='mr-2' />
                  <a className='text-white' href='tel:+62354774040'>
                    (0354) 774 040
                  </a>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <ImPhone className='mr-2' />
                  <a className='text-white' href='tel:+682140009403'>
                    082 140 0094 03
                  </a>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <ImPrinter className='mr-2' />
                  <a className='text-white' href='tel:+62354771539'>
                    (0354) 771 539
                  </a>
                </Col>
              </Row>
            </Col>
            <Col>
              <h6 className='mb-3'>Jadwal Kerja</h6>
              <Row>
                <Col xs={"3"}>Senin</Col>
                <Col>
                  : Praktek 08.00 - 13.00, Swab &amp; Assesment 07.30 - 15.00,
                  Penunjang<sup>*</sup> 07.30 - 15.00
                </Col>
              </Row>
              <Row>
                <Col xs={"3"}>Selasa</Col>
                <Col>
                  : Praktek 08.00 - 13.00, Swab &amp; Assesment 07.30 - 15.00,
                  Penunjang<sup>*</sup> 07.30 - 15.00
                </Col>
              </Row>
              <Row>
                <Col xs={"3"}>Rabu</Col>
                <Col>
                  : Praktek 08.00 - 13.00, Swab &amp; Assesment 07.30 - 15.00,
                  Penunjang<sup>*</sup> 07.30 - 15.00
                </Col>
              </Row>
              <Row>
                <Col xs={"3"}>Kamis</Col>
                <Col>
                  : Praktek 08.00 - 13.00, Swab &amp; Assesment 07.30 - 15.00,
                  Penunjang<sup>*</sup> 07.30 - 15.00
                </Col>
              </Row>
              <Row>
                <Col xs={"3"}>Jumat</Col>
                <Col>
                  : Praktek 08.00 - 13.00, Swab &amp; Assesment 07.30 - 15.00,
                  Penunjang<sup>*</sup> 07.30 - 15.00
                </Col>
              </Row>
              <Row>
                <Col xs={"3"}>Sabtu</Col>
                <Col>
                  : Praktek 08.00 - 12.00, Swab &amp; Assesment 07.30 - 13.00,
                  Penunjang<sup>*</sup> 08.00 - 14.00
                </Col>
              </Row>
              <div>
                <em>
                  *Layanan Penunjang tersedia meliputi Farmasi, Radiologi dan
                  Laboratorium
                </em>
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col sm={{ offset: 3, span: 6 }}>
              <Row>
                <Col xs={"3"} sm={"1"}>
                  <a href='https://www.facebook.com/iikbwkediri'>
                    <Img
                      fluid={
                        data.files.edges.find(
                          ({ node: { name } }) => name === "facebook"
                        ).node.childImageSharp.fluid
                      }
                    />
                  </a>
                </Col>
                {/* <Col xs={'3'} sm={'1'}>
                  <a href='#'>
                    <Img
                      fluid={
                        data.files.edges.find(
                          ({ node: { name } }) => name === 'twitter'
                        ).node.childImageSharp.fluid
                      }
                    />
                  </a>
                </Col> */}
                <Col xs={"3"} sm={"1"}>
                  <a href='https://www.instagram.com/rsgmiikbw/'>
                    <Img
                      fluid={
                        data.files.edges.find(
                          ({ node: { name } }) => name === "instagram"
                        ).node.childImageSharp.fluid
                      }
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
