import { useLocation } from "@reach/router";
import { ImSearch } from "@react-icons/all-files/im/ImSearch";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useTriggerTransition } from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PropTypes from "prop-types";
import qs from "qs";
import React, { useState } from "react";
import {
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import fadeTransition from "./fadeTransition";

export const COLOR = "#c2912e";

const Header = ({ siteTitle }) => {
  const location = useLocation();
  const data = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "logo-2023" }) {
        childImageSharp {
          fixed(width: 64, toFormat: WEBP, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const fadeNavigate = useTriggerTransition({
    exit: {
      length: 1,
      trigger: ({ exit, node }) =>
        fadeTransition({ exit, node, direction: "out" }),
    },
    entry: {
      length: 0.5,
      delay: 0.5,
      trigger: ({ exit, node }) =>
        fadeTransition({ exit, node, direction: "in" }),
    },
  });
  const query = location.search ? qs.parse(location.search.substring(1)) : {};
  const [searchValue, setSearchValue] = useState(query.search || "");
  const handleSearch = (event) => setSearchValue(event.target.value);

  const isActive = (link, relative = false) =>
    relative
      ? location?.pathname.startsWith(link)
      : location?.pathname === link;

  const searchable =
    location?.pathname.includes(`careers`) ||
    location?.pathname.includes(`news`);

  const search = (event) => {
    event.preventDefault();

    const path = location?.pathname.includes(`careers`) ? "careers" : "news";

    fadeNavigate({
      to: `/${path}?search=${event.currentTarget.elements.search.value.trim()}`,
    });
  };

  return (
    <header css={{ position: "sticky", top: 0, zIndex: 2 }}>
      <Navbar bg='light' expand='lg'>
        <Container>
          <Navbar.Brand>
            <Img fixed={data.logo.childImageSharp.fixed} />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className='mr-auto flex-lg-row align-items-lg-center align-items-stretch align-content-center'>
              <AniLink
                paintDrip
                hex={COLOR}
                to='/'
                className={`nav-link ${
                  isActive("/") ? "btn btn-primary text-white" : ""
                }`}>
                Beranda
              </AniLink>
              <NavDropdown
                title='Tentang RSGM'
                active={[
                  "history",
                  "motto",
                  "organization",
                  "facilities",
                  "partners",
                ].some((link) => isActive(`/about/${link}`))}
                css={{ marginRight: 0 }}>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/about/history'
                  className={`dropdown-item ${
                    isActive("/about/history") ? "active" : ""
                  }`}>
                  Sejarah
                </AniLink>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/about/motto'
                  className={`dropdown-item ${
                    isActive("/about/motto") ? "active" : ""
                  }`}>
                  Motto
                </AniLink>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/about/organization'
                  className={`dropdown-item ${
                    isActive("/about/organization") ? "active" : ""
                  }`}>
                  Struktur Organisasi
                </AniLink>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/about/facilities'
                  className={`dropdown-item ${
                    isActive("/about/facilities") ? "active" : ""
                  }`}>
                  Fasilitas
                </AniLink>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/about/partners'
                  className={`dropdown-item ${
                    isActive("/about/partners") ? "active" : ""
                  }`}>
                  Partner
                </AniLink>
              </NavDropdown>
              <NavDropdown
                title='Layanan RSGM IIK BW'
                active={[
                  "emergency",
                  "outpatient",
                  "inpatient",
                  "supporting",
                ].some((link) => isActive(`/services/${link}`))}
                css={{ marginRight: 0 }}>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/services/emergency'
                  className={`dropdown-item ${
                    isActive("/services/emergency") ? "active" : ""
                  }`}>
                  Instalasi Gawat Darurat
                </AniLink>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/services/outpatient'
                  className={`dropdown-item ${
                    isActive("/services/outpatient") ? "active" : ""
                  }`}>
                  Rawat Jalan
                </AniLink>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/services/inpatient'
                  className={`dropdown-item ${
                    isActive("/services/inpatient") ? "active" : ""
                  }`}>
                  Rawat Inap
                </AniLink>
                <AniLink
                  paintDrip
                  hex={COLOR}
                  to='/services/supporting'
                  className={`dropdown-item ${
                    isActive("/services/supporting") ? "active" : ""
                  }`}>
                  Penunjang
                </AniLink>
              </NavDropdown>
              <AniLink
                paintDrip
                hex={COLOR}
                to='/news'
                className={`nav-link ${
                  isActive("/news") ? "btn btn-primary text-white" : ""
                }`}>
                News
              </AniLink>
              <AniLink
                paintDrip
                hex={COLOR}
                to='/careers'
                className={`nav-link ${
                  isActive("/careers") ? "btn btn-primary text-white" : ""
                }`}>
                Info Karir
              </AniLink>
              {/* <AniLink
                paintDrip
                hex       = {COLOR}
                to        = '/contact'
                className = {`nav-link ${
                  isActive('/contact')
                    ? 'btn btn-primary text-white mb-1 mb-md-0'
                    :  ''
                }`}>
                Kontak
              </AniLink> */}
              <AniLink
                paintDrip
                hex={COLOR}
                to='/quality'
                className={`nav-link ${
                  isActive("/quality", true) ? "btn btn-primary text-white" : ""
                }`}>
                Indikator Mutu Layanan
              </AniLink>
              <AniLink
                paintDrip
                hex={COLOR}
                to='/rooms'
                className={`nav-link ${
                  isActive("/rooms", true) ? "btn btn-primary text-white" : ""
                }`}>
                Bed Inap
              </AniLink>
              <Nav.Link href='https://library.rsgmiikbw.com/'>
                Perpustakaan Digital
              </Nav.Link>
              <Nav.Link href='https://rsgmiikbw.com/daftar'>
                Daftar Online
              </Nav.Link>
            </Nav>
            {searchable && (
              <Form inline onSubmit={search}>
                <InputGroup className='mb-3'>
                  <FormControl
                    placeholder='Cari'
                    aria-label='Cari'
                    name='search'
                    value={searchValue}
                    onChange={handleSearch}
                  />
                  <InputGroup.Append>
                    <Button type='submit' variant='outline-secondary'>
                      <ImSearch />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
