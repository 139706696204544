import { TimelineMax } from 'gsap';

const fadeTransition = ({ exit: { length }, node, direction }) => {
  const duration  = direction === 'out' ? length + length / 4 : length;
  const opacity   = direction === 'in' ? 1 : 0;
  const scrollTop = 
    (document.scrollingElement && document.scrollingElement.scrollTop) ||
    document.body.scrollTop ||
    (typeof window !== `undefined` && window.pageYOffset);

  const holdPosition = 
    direction === 'out'
      ? {
          overflowY: 'hidden',
          height   : '100vh',
          scrollTop: scrollTop,
        }
      :  {};

  return new TimelineMax()
    .set(node, holdPosition)
    .fromTo(node, duration, { opacity: !opacity }, { opacity: opacity });
};

export default fadeTransition;
