// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-facilities-js": () => import("./../../../src/pages/about/facilities.js" /* webpackChunkName: "component---src-pages-about-facilities-js" */),
  "component---src-pages-about-history-js": () => import("./../../../src/pages/about/history.js" /* webpackChunkName: "component---src-pages-about-history-js" */),
  "component---src-pages-about-motto-js": () => import("./../../../src/pages/about/motto.js" /* webpackChunkName: "component---src-pages-about-motto-js" */),
  "component---src-pages-about-organization-js": () => import("./../../../src/pages/about/organization.js" /* webpackChunkName: "component---src-pages-about-organization-js" */),
  "component---src-pages-about-partners-js": () => import("./../../../src/pages/about/partners.js" /* webpackChunkName: "component---src-pages-about-partners-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-services-emergency-js": () => import("./../../../src/pages/services/emergency.js" /* webpackChunkName: "component---src-pages-services-emergency-js" */),
  "component---src-pages-services-inpatient-js": () => import("./../../../src/pages/services/inpatient.js" /* webpackChunkName: "component---src-pages-services-inpatient-js" */),
  "component---src-pages-services-outpatient-js": () => import("./../../../src/pages/services/outpatient.js" /* webpackChunkName: "component---src-pages-services-outpatient-js" */),
  "component---src-pages-services-supporting-js": () => import("./../../../src/pages/services/supporting.js" /* webpackChunkName: "component---src-pages-services-supporting-js" */),
  "component---src-templates-quality-trimester-js": () => import("./../../../src/templates/quality/trimester.js" /* webpackChunkName: "component---src-templates-quality-trimester-js" */),
  "component---src-templates-quality-year-js": () => import("./../../../src/templates/quality/year.js" /* webpackChunkName: "component---src-templates-quality-year-js" */)
}

